/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "jwVideoArticle": {
    "extend": {
      "size_vertical": "!mb-0 !pb-0 !h-fit",
      "size_horizontal": "!mb-0 !pb-0 !h-fit"
    }
  },
  "icon": {
    "variant_default": "rotate-90 data-[open]:rotate-270",
    "size_small": "h-3 mt-0.5"
  },
  "prominentTag_colors_default": "text-gray-700",
  "prominentTag_size_default": "",
  "prominentTag_variant_default": "capitalize",
  "title_colors_default": "",
  "title_size_default": "",
  "title_variant_default": "",
  "description_colors_default": "",
  "description_size_default": "",
  "description_variant_default": "",
  "tags_colors_default": "",
  "tags_size_default": "gap-3",
  "tags_variant_default": "flex flex-wrap",
  "group_colors_default": "",
  "group_size_default": "gap-6",
  "group_variant_default": "flex flex-col justify-center",
  "actions_colors_default": "",
  "actions_size_default": "gap-6",
  "actions_variant_default": "flex flex-row",
  "actions_share_colors_default": "text-gray-700",
  "actions_share_size_default": "",
  "actions_share_variant_default": "",
  "actions_transcript_colors_default": "text-gray-700",
  "actions_transcript_size_default": "gap-2",
  "actions_transcript_variant_default": "flex flex-row",
  "actions_modal_colors_default": "bg-white/90 backdrop:bg-black/40",
  "actions_modal_size_default": "gap-4",
  "actions_modal_variant_default": "hidden open:flex flex-row rounded-lg",
  "variant_horizontal": "grid lg:grid-cols-[3fr_2fr]",
  "variant_vertical": "grid lg:grid-cols-[400px_600px]",
  "colors_default": "",
  "size_default": "gap-4 lg:gap-x-12"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "JwVideoVideo");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;